import axios from '../global/plugins/axios';
import { Api } from '../global/api';

export const urls = {
	log: {
		grid: '/options-changes/grid',
		diff: id => `/options-changes/${id}/diff`,
		request: id => `/options-changes/${id}/request-body`,
		response: id => `/options-changes/${id}/response-body`
	},
	settings: {
		manufacturersCrmId: 'manufacturers?has_crm_id=true',
		updateOptions: manufacturerId => `/manufacturers/${manufacturerId}/start-update-options`,
		updatePrices: manufacturerId => `/manufacturers/${manufacturerId}/start-update-prices`,
		pullProductInfo: manufacturerId => `/manufacturers/${manufacturerId}/pull-product-info-from-aq`,
		options: manufacturerId => `/manufacturers/${manufacturerId}/options/settings`,
		optionDescriptions: manufacturerId => `/manufacturers/${manufacturerId}/option-descriptions`,
		blackList: manufacturerId => `/manufacturers/${manufacturerId}/options/black-list`,
		importNewProducts: manufacturerId => `/manufacturers/${manufacturerId ? `${manufacturerId}/` : ''}create-new-products-crm`,
	},
	products: {
		grid: 'products/grid',
		discountCategories: query =>  `/products/grid/discount-categories${query ? `?${query}` : ''}`,
		commandBatch: query => `/products/command-batch${query ? `?${query}` : ''}`,
		config: (product_id, query) => `/products/${product_id}/crm-product-config${query ? `?${query}` : ''}`,
		ignore: query => `/products/crm-product-ignore-batch${query ? `?${query}` : ''}`,
		setMargin: query => `/products/crm-product-set-margin-batch${query ? `?${query}` : ''}`
	},
	batchAction: {
		updateBrandsFromCrm: 'manufacturers/update-from-crm',
		startProcessing: query => `manufacturers/start-processing${query ? `?${query}` : ''}`
	},
	tasks: {
		grid: 'tasks/grid',
		statuses: 'tasks/grid/statuses',
		root: id => `tasks/${id}`,
		errors: id => `tasks/${id}/errors`
	}
};


class ExpensesApi extends Api {
	#urls = null;
	
	constructor(urls) {
		super();
		this.#urls = urls;
	}
	getQuery(payload) {
		return new URLSearchParams(payload).toString();
	}
	
	fetchLogGrid() {
		return this.#urls.log.grid;
	}
	fetchProductsGrid() {
		return this.#urls.products.grid;
	}
	fetchTasksGrid() {
		return this.#urls.tasks.grid;
	}
	async fetchTaskStatuses() {
		return await this.request(axios.get(this.#urls.tasks.statuses), 'Can\'t fetch task statuses');
	}
	async fetchTaskErrors(id) {
		return await this.request(axios.get(this.#urls.tasks.errors(id)), 'Can\'t fetch task errors');
	}
	async fetchTask(id) {
		return await this.request(axios.get(this.#urls.tasks.root(id)), 'Can\'t fetch task data');
	}
	
	async fetchManufacturers() {
		return await this.request(axios.get(this.#urls.settings.manufacturersCrmId), 'Can\'t fetch manufacturers');
	}
	async fetchDiscountCategories(requestData) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.get(this.#urls.products.discountCategories(query)), 'Can\'t fetch discount categories');
	}
	async putSetMargin(requestData, payload) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.put(this.#urls.products.setMargin(query), payload), 'Can\'t update product');
	}
	async fetchUpdateBrandsFromCrm() {
		return await this.request(axios.get(this.#urls.batchAction.updateBrandsFromCrm), 'Can\'t update brands from crm ');
	}
	
	async fetchOptionList(manufacturerId) {
		return await this.request(axios.get(this.#urls.settings.optionDescriptions(manufacturerId)), 'Can\'t fetch options');
	}
	async fetchBlackList(manufacturerId) {
		return await this.request(axios.get(this.#urls.settings.blackList(manufacturerId)), 'Can\'t fetch black list');
	}
	async fetchImportNewProducts(manufacturerId) {
		return await this.request(axios.get(this.#urls.settings.importNewProducts(manufacturerId), { timeout: 0 }), 'Can\'t import new products');
	}
	async putBlackList(manufacturerId, payload) {
		return await this.request(axios.put(this.#urls.settings.blackList(manufacturerId), payload), 'Can\'t update black list');
	}
	async fetchOptionsSettings(manufacturerId) {
		return await this.request(axios.get(this.#urls.settings.options(manufacturerId)), 'Can\'t fetch settings');
	}
	async putOptionsSettings(manufacturerId, payload) {
		return await this.request(axios.put(this.#urls.settings.options(manufacturerId), payload), 'Can\'t update settings');
	}
	async postUpdateOptionsSettings(manufacturerId, payload) {
		return await this.request(axios.post(this.#urls.settings.updateOptions(manufacturerId), payload), 'Can\'t start update options');
	}
	async postUpdatePricesSettings(manufacturerId, payload) {
		return await this.request(axios.post(this.#urls.settings.updatePrices(manufacturerId), payload), 'Can\'t start update prices');
	}
	async fetchProductInfoFromAqSettings(manufacturerId) {
		return await this.request(axios.get(this.#urls.settings.pullProductInfo(manufacturerId)), 'Can\'t pull product info');
	}
	async postProductsCommandBatch(requestData, payload) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.post(this.#urls.products.commandBatch(query), payload), 'Can\'t add to crm');
	}
	async putProductsConfig(productId, requestData, payload) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.put(this.#urls.products.config(productId, query), payload), 'Can\'t update product');
	}
	async putProductsIgnore(requestData, payload) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.put(this.#urls.products.ignore(query), payload), 'Can\'t update ignore product');
	}
	async postStartProcessing(requestData, payload) {
		const query = requestData ? this.getQuery(requestData) : null;
		
		return await this.request(axios.post(this.#urls.batchAction.startProcessing(query), payload), 'Can\'t update ignore product');
	}
}

export default new ExpensesApi(urls);
